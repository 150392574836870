import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const AdminLogin = ({ setToken }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const [error, setError] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!username || !password) {
      setMessageType("error");
      setMessage("Please fill Username and password.");
      setShowMessage(true);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/login`,
        { username, password }
      );
      // alert(username + ' ' + password);
      if (response.status === 200 && response.data.token) {
        localStorage.setItem("token", response.data.token);
        setToken(response.data.token);
        navigate("/admin");
        // navigate("/dashboard");
      }
      // localStorage.setItem("token", response.data.token);
      // setToken(response.data.token);
      // navigate("/upload");
    } catch (err) {
      setMessageType("error");
      setMessage("Incorrect username or password");
      setShowMessage(true);
    }
  };

  return (
    <div className="upload-container">
      <div className="page-head">
        <div className="page-head-container">
          <span>Admin Login</span>
        </div>
      </div>
      <form className="login-form" onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <img src="/Images/adminLogin.png" alt=""></img>
        </div>
        {showMessage && (
          <p className={`${messageType} ${!showMessage && "hidden"}`}>
            {message}
          </p>
        )}
        <div className="form-group">
          <label>User Name</label>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button type="submit" className="adminlogin-button">
            Submit
          </button>
          <button type="button" className="adminlogin-button">
            <Link to="/" className="adminlogin-link">
              Cancel
            </Link>
          </button>
        </div>

        {/* <button type="submit">Login</button> */}
      </form>
    </div>
  );
};

export default AdminLogin;
