import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
function Registeruser() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const formRegister = useRef(null);

  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");

  // Display message handeling for 3 seconds
  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showMessage]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic validation
    if (!username || !email || !password || !confirmpassword) {
      setMessageType("error");
      setMessage("Please fill in all the fields"); //file uploaded successfully
      setShowMessage(true);
      return;
    } else {
      navigate("/");
      //logic to save into db the registration details
    }

    // Clear form fields
    // setEmail("");
    // setPassword("");
    // setMessage("");
  };

  return (
    <div className="upload-container">
      <div className="page-head">
        <div className="page-head-container">
          <span>Registration</span>
        </div>
      </div>
      <form className="login-form" ref={formRegister} onSubmit={handleSubmit}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src="/Images/registration.png" alt=""></img>
        </div>
        {showMessage && (
          <p className={`${messageType} ${!showMessage && "hidden"}`}>
            {message}
          </p>
        )}

        <div className="form-group">
          <label>User Name:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter your email"
            required
          />
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
        </div>

        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            required
          />
        </div>
        <div className="form-group">
          <label>Confirm Password:</label>
          <input
            type="password"
            value={confirmpassword}
            onChange={(e) => setConfirmpassword(e.target.value)}
            placeholder="confirm your password"
            required
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            type="submit"
            className="adminlogin-button"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button type="button" className="adminlogin-button">
            <Link to="/" className="adminlogin-link">
              Cancel
            </Link>
          </button>
        </div>
      </form>
    </div>
  );
}

export default Registeruser;
