import React from "react";

function CollegeLocation() {
  return (
    <div>
      <iframe
        title="Location"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7001.263897972789!2d78.073344!3d28.670737!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390b65196658ae6d%3A0x4996d7219c714a4!2sMaheshwaram%20College%20of%20Education%20and%20Law%20Management!5e0!3m2!1sen!2sin!4v1723656167112!5m2!1sen!2sin"
        width="100%"
        height="100%"
        style={{ border: "0" }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>
  );
}

export default CollegeLocation;
