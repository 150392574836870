import React from "react";

function AimandObjective() {
  return (
    <div>
      <div className="page-head">
        <span>Aims and Objectives</span>
      </div>
      <p className="tabbed-paragraph">
        At Maheshwaram College of Education and Law Management, our vision is to
        set a benchmark in the realm of legal education through the adoption of
        innovative teaching and training methodologies. Our aims and objectives
        are:
      </p>

      <p className="tabbed-paragraph">
        1. Establish a Hub of Excellence: To emerge as a premier center for
        legal education by embracing cutting-edge teaching techniques and
        training methodologies that reflect the highest standards of academic
        and professional excellence.
      </p>
      <p className="tabbed-paragraph">
        2. Deliver High-Quality Education: To provide top-notch legal education
        and professional training that equips our students with the knowledge
        and skills needed to excel in the legal profession.
      </p>
      <p className="tabbed-paragraph">
        3. Prepare Versatile Legal Professionals: To nurture law graduates who
        are well-prepared to pursue distinguished careers in various fields,
        including practicing at the Bar, competing for prestigious judicial and
        civil service positions, or securing roles in national and multinational
        corporations.
      </p>
      <p className="tabbed-paragraph">
        4. Foster Academic and Ethical Excellence: To instill academic rigor,
        discipline, and a strong sense of ethical and moral values in our
        students, while promoting national integration and a commitment to
        personal character development.
      </p>
      <p className="tabbed-paragraph">
        5. Encourage Holistic Development: To support and promote sports,
        co-curricular activities, and other initiatives that contribute to the
        overall personality development of our students, ensuring they are
        well-rounded and prepared for diverse challenges.
      </p>
    </div>
  );
}

export default AimandObjective;
