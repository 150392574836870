import React from "react";

function Discipline() {
  return (
    <div>
      <div className="page-head">
        <span>Discipline & Code of Conduct</span>
      </div>
      <p className="tabbed-paragraph">
        Being a Law College, Maheshwaram College of Education and Law Management
        insists on high standard of discipline from the students for enhancing
        the status and reputation of the college of excellence in legal
        education. The norms and standards for students’ behavior will be lay
        down, from time to time and the principal shall be the authority to take
        necessary action against the violator(s) of rules and regulations. The
        following Code of Conduct to be adhered by the all students of College.
        Any violation of code of conduct will result in disciplinary action
        against violator(s):-
      </p>
      <p>
        <ol>
          <li>
            Do not indulge in any act that will tarnish the image of the
            College.
          </li>
          <li>Be respectful to all staff/faculty members.</li>
          <li>Be dressed as specified in the Dress Code.</li>
          <li>
            Do not indulge in smoking. Possession and consumption of all kinds
            of alcoholic beverages are strictly prohibited.
          </li>
          <li>
            Treat College property with care and help to keep the campus clean
            and pollution free.
          </li>
          <li>
            Students are advised to see the notices displayed on the notice
            board(s) of College. The notice displayed on the notice boards
            deemed to have served on the students.
          </li>
          <li>
            Don’t participate in any political, antinational, anti-social or
            undesirable activity in or outside the campus.
          </li>
          <li>Absence in class without leave is liable to fine.</li>
          <li>
            Do not indulge in any form of ragging which is STRICTLY PROHIBITED
            as per the directions of the Hon’ble Supreme Court of India.
          </li>
          <li>
            The decision of the Chairman of Maheshwaram College of Education and
            Law Management shall be final in all the matters.
          </li>
        </ol>
      </p>
    </div>
  );
}

export default Discipline;
