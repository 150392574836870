import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "./Components/Footer";
import LatestCirculars from "./Components/LatestCirculars";
import Navbar from "./Components/Navbar";
import Brand from "./Components/UI/Brand";
import Search from "./Components/UI/Search";
import SocialNavbar from "./Components/UI/SocialNavbar";
import Sidebar from "./Components/UI/Sidebar";
import AllCirculars from "./pages/AllCirculars";
import CircularsFromfile from "./Components/CircularsFromfile";
function Layout() {
  const [toggleSidebar, setToggleSidebar] = useState(false);

  const navigate = useNavigate();
  const handleReadMore = () => {
    navigate("/admission/allcirculars");
  };
  return (
    <>
      <div
        className={` ${toggleSidebar ? "dull" : ""}`}
        // onClick={() => setToggleSidebar(false)}
      >
        {/* ------------------------------ */}
        <div className="social-navbar-bg-color">
          <div className="socialNavbarContainer">
            <SocialNavbar />
            <div style={{ margin: "0.5rem 0" }}>
              <Search />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="header">
            <Brand />
          </div>
        </div>
        <div className="navbarContainer">
          <Sidebar
            toggleSidebar={toggleSidebar}
            setToggleSidebar={setToggleSidebar}
          />
          <nav className="navbar">
            <button
              className="sidebar-icon"
              onClick={() => setToggleSidebar(!toggleSidebar)}
            >
              {toggleSidebar ? (
                <svg fill="none" viewBox="0 0 15 15" height="1em" width="1em">
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M11.782 4.032a.575.575 0 10-.813-.814L7.5 6.687 4.032 3.218a.575.575 0 00-.814.814L6.687 7.5l-3.469 3.468a.575.575 0 00.814.814L7.5 8.313l3.469 3.469a.575.575 0 00.813-.814L8.313 7.5l3.469-3.468z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  viewBox="0 0 448 512"
                  fill="currentColor"
                  height="1em"
                  width="1em"
                >
                  <path d="M0 96c0-17.7 14.3-32 32-32h384c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32h384c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zm448 160c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h384c17.7 0 32 14.3 32 32z" />
                </svg>
              )}
            </button>
            <Navbar />
          </nav>
        </div>
        <img
          className="hero-image"
          src="/Images/onlineregistration.png"
          alt="Registration"
        ></img>
        <div className="scroll-container">
          <div className="scroll-text">
            Maheshwaram College of Education and Law Mangement
          </div>
        </div>
        <div className="container">
          <div className="bodyContainer">
            <div className="bodyLeftColumn">
              <Outlet />
            </div>
            <div className="bodyRightColumn">
              {/* <div className="linkRegisterNow">
                <Link to="/register">
                  <img
                    className="brand-register-image"
                    src="/Images/reg.gif"
                    alt="Register"
                  />
                </Link>
              </div> */}
              <div className="box-head">
                <span>Latest Circulars</span>
              </div>
              {/* LatestCirculars is loading from database  */}
              {/* <LatestCirculars /> */}
              <CircularsFromfile />
              <div style={{ textAlign: "right" }}>
                <button className="readMoreButton" onClick={handleReadMore}>
                  view all
                </button>
              </div>
              <div className="divider-full-width"></div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Layout;
