import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logout from "./Logout";
const menuItems = [
  { name: "Upload Photo", link: "/admin/uploadphoto" },
  { name: "Manage Photos", link: "/admin/admingallery" },
  { name: "Upload Circular", link: "/admin/uploadcircular" },
  { name: "Manage Circular", link: "/admin/admincirculars" },
];

const AdminNavbar = () => {
  // State to track which menu item is active for submenu visibility
  const [activeMenu, setActiveMenu] = useState(null);

  // Handle mouse enter to show submenu
  const selectedNavbarMenuItem = (name) => {
    setActiveMenu(name);
  };

  // Handle mouse leave to hide submenu
  const handleMouseLeave = () => {
    setActiveMenu(null);
  };

  return (
    <div className="adminnavbar-container">
      <ul id="Adminnavbar-menu">
        {menuItems.map((item, index) => (
          <li
            key={index}
            // className={`${activeMenu === index ? "activeNavbarItem" : ""}`}
            onMouseEnter={() => item.submenu && selectedNavbarMenuItem(index)}
            onMouseLeave={() =>
              item.submenu &&
              handleMouseLeave() &&
              selectedNavbarMenuItem(index)
            }
            className="adminnavbar-container-li"
          >
            <Link
              to={item.link}
              className="adminnavbar-link"
              // className={`adminnavbar-link
              //   ${item.name === "Admin Panel" ? "admin-panel" : ""}`}
            >
              {item.name}
            </Link>

            {/* Submenu items */}
            {item.submenu && activeMenu === index && (
              <ul className="adminvar-submenu-ul">
                {item.submenu.map((subItem, subIndex) => (
                  <li key={subIndex} className="adminvar-submenu-li">
                    <Link to={subItem.link} className="adminbar-submenu-link">
                      {subItem.name}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
      <div className="adminbar-logout-container">
        <Logout />
      </div>
    </div>
  );
};

export default AdminNavbar;
