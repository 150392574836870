import React from "react";

function Recognition() {
  return (
    <div>
      Maheshwaram College of Education and Law Management is affilated to CCS
      University.
    </div>
  );
}

export default Recognition;
