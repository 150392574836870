import React from "react";

function Search() {
  return (
    <div className="search-container">
      <div style={{ display: "flex", alignItems: "center" }}>
        <input type="text" placeholder="Search..." className="search-input" />
        <button className="search-button">
          <svg
            className="search-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
          <span className="sr-only">Search</span>
        </button>
      </div>
    </div>
  );
}

export default Search;
