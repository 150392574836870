import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
function LoginForm() {
  // const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  // Display message handeling for 3 seconds
  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showMessage]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic validation
    if (!email || !password) {
      setMessageType("error");
      setMessage("Please fill in all fields");
      setShowMessage(true);
      return;
    }
    //for debugging purpose only
    // console.log("Login attempt:", { email, password });
    if (email === "mlc@gmail.com") {
      if (password === "mlc@1234") {
        // Handle login logic here (API call or authentication logic)
        // for testing I am Navigating to the home page
        // navigate("/");
        // Clear form fields
        setEmail("");
        setPassword("");
        setMessageType("success");
        setMessage("Information is correct");
        setShowMessage(true);
        // Clear form fields
        setEmail("");
        setPassword("");
      } else {
        setMessageType("error");
        setMessage("Invalid password for the email address");
        setShowMessage(true);
        setPassword("");
        return;
      }
    } else {
      setMessageType("error");
      setMessage("incorrect email address or password ");
      setShowMessage(true);
      return;
    }
  };

  return (
    <div className="upload-container">
      <div className="page-head">
        <div className="page-head-container">
          <span>Student Login</span>
        </div>
        <div className="linkRegisterNow">
          <Link to="/registeruser">
            <img
              className="brand-register-image"
              src="/Images/reg.gif"
              alt="Register"
            />
          </Link>
        </div>
      </div>
      <form className="login-form" onSubmit={handleSubmit}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src="/Images/studentlogin.png" alt=""></img>
        </div>
        {showMessage && (
          <p className={`${messageType} ${!showMessage && "hidden"}`}>
            {message}
          </p>
        )}
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
        </div>

        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            required
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            type="submit"
            className="adminlogin-button"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button type="button" className="adminlogin-button">
            <Link to="/" className="adminlogin-link">
              Cancel
            </Link>
          </button>
        </div>
      </form>
    </div>
  );
}

export default LoginForm;
