import React, { useEffect, useState, useRef } from "react";
import axios from "axios";


const AllCirculars = () => {
  
  const [pdfArray, setPdfArray] = useState([]);

  //for vertical scrolling effect
  const listRef = useRef(null);
  const scrollIntervalRef = useRef(null);

  useEffect(() => {
    const listElement = listRef.current;

    const startAutoScroll = () => {
      scrollIntervalRef.current = setInterval(() => {
        if (listElement) {
          // Scroll down by 1 pixel
          listElement.scrollTop += 1;

          // If the end is reached, reset to top
          if (
            listElement.scrollTop + listElement.clientHeight >=
            listElement.scrollHeight
          ) {
            listElement.scrollTop = 0;
          }
        }
      }, 50); // Adjust speed here (50ms delay between scrolls)
    };

    startAutoScroll();

    // Clear the interval on component unmount
    return () => clearInterval(scrollIntervalRef.current);
  }, []);

  const handleMouseEnter = () => {
    // Pause the scroll when hovering over the list
    clearInterval(scrollIntervalRef.current);
  };

  const handleMouseLeave = () => {
    // Resume the scroll when not hovering
    const listElement = listRef.current;
    scrollIntervalRef.current = setInterval(
      () => {
        if (listElement) {
          listElement.scrollTop += 1;

          if (
            listElement.scrollTop + listElement.clientHeight >=
            listElement.scrollHeight
          ) {
            listElement.scrollTop = 0;
          }
        }
      },
      50 //scroll speed  moves 1 pxl every 50ms
    );
  };

  //call the Api to get list of circulars
  useEffect(() => {
    const fetchedPdfFiles = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/pdflist`
        );
        setPdfArray(response.data.pdfArray);
      } catch (error) {
        console.error("Error fetching PDF files:", error);
      }
    };

    fetchedPdfFiles();
  }, []);

  return (
    <div>
      {/* <h6 className="divider-full-width">Uploaded Circulars</h6> */}
      <div className="page-head">
        <span>Notices & Circulars</span>
      </div>
      <table className="pdf-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Posted On</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          {pdfArray.map((pdf) => (
            <tr key={pdf.id}>
              <td>
                <img
                  src="/images/pdf.png"
                  alt=""
                  style={{ width: "24px", height: "24px", marginRight: "8px" }}
                />
                {pdf.realname}
              </td>
              <td style={{ textAlign: "center" }}>{pdf.uploaded_at}</td>
              <td style={{ textAlign: "center" }}>
                <a href={pdf.url} download={pdf.name}>
                  Download
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="divider-full-width"></div>
    </div>
  );
};

export default AllCirculars;
