import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Admincirculars = ({ token }) => {
  const [pdfList, setpdfList] = useState([]);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const navigate = useNavigate();

  // common functions
  const showMessageWithType = (type, msg) => {
    setMessageType(type);
    setMessage(msg);
    setShowMessage(true);
  };

  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 5000); // Display message for 3 seconds
      return () => clearTimeout(timer); // Clear timeout if component unmounts
    }
  }, [showMessage]);

  //----------------
  useEffect(() => {
    // Check if the token is present and valid
    const checkToken = () => {
      if (!token) {
        showMessageWithType(
          "error",
          "You need to be logged in to access this page."
        );
        navigate("/adminlogin"); // Redirect to login if no token
        return;
      }

      // Optionally check token validity (e.g., decode and check expiration)
      try {
        const { exp } = JSON.parse(atob(token.split(".")[1])); // Decode JWT payload
        if (exp * 1000 < Date.now()) {
          // Token is expired
          showMessageWithType(
            "error",
            "Your session has expired. Please log in again."
          );
          navigate("/adminlogin");
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        showMessageWithType(
          "error",
          "An error occurred while validating your session. Please log in again."
        );
        navigate("/adminlogin");
      }
    };

    checkToken();
  }, [token, navigate]);
  //common function ends

  // Call API to Fetch uploaded Files from the server
  useEffect(() => {
    const fetchedPdfFiles = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/pdflist`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("fetched data is : ", response.data.pdfArray);
        setpdfList(response.data.pdfArray); // Adjust based on your API response
      } catch (err) {
        console.error("Error fetching files:", err);
        setMessageType("error");
        setMessage("Failed to load file. Please try again.");
      }
    };
    fetchedPdfFiles();
  }, [token]); // Re-fetch files whenever the token changes
  // }, [token]);
  // Call API to delete uploaded File from the server
  const handleDelete = async (pdfname) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/delete-pdf/${pdfname}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Remove the deleted image from the list
      setpdfList(pdfList.filter((img) => img.name !== pdfname));
      showMessageWithType("success", "File deleted successfully");
    } catch (err) {
      console.error("Error deleting File:", err);
      showMessageWithType(
        "error",
        "Failed to delete File. Please Login again &  try."
      );
    }
  };
  return (
    <div className="container">
      {showMessage && (
        <p className={`${messageType} ${!showMessage && "hidden"}`}>
          {message}
        </p>
      )}
      <h6 className="divider-full-width">Uploaded Circulars</h6>
      <table className="pdf-table">
        <thead>
          <tr>
            <th>Circular Name</th>
            {/* <th>Circular Unique Name</th> */}
            <th>Download</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {pdfList.map((pdf) => (
            <tr key={pdf.id}>
              <td>{pdf.realname}</td>
              {/* <td>{pdf.name}</td> */}
              <td style={{ textAlign: "center" }}>
                <a href={pdf.url} download={pdf.name}>
                  Download
                </a>
              </td>
              <td style={{ textAlign: "center" }}>
                <button onClick={() => handleDelete(pdf.name)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="divider-full-width"></div>
    </div>
  );
};

export default Admincirculars;
