import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faInstagram,
  faYoutube,
  faWhatsapp,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";
// function SocialNavbar() {
const SocialNavbar = () => {
  return (
    <div className="social-navbar">
      <a
        href="https://facebook.com"
        target="_blank"
        rel="noreferrer"
        className="social-link"
      >
        <FontAwesomeIcon icon={faFacebookF} />
      </a>
      <a
        href="https://twitter.com"
        target="_blank"
        rel="noreferrer"
        className="social-link"
      >
        <FontAwesomeIcon icon={faTwitter} />
      </a>
      <a
        href="https://linkedin.com"
        target="_blank"
        rel="noreferrer"
        className="social-link"
      >
        <FontAwesomeIcon icon={faLinkedinIn} />
      </a>
      <a
        href="https://instagram.com"
        target="_blank"
        rel="noreferrer"
        className="social-link"
      >
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a
        href="https://youtube.com"
        target="_blank"
        rel="noreferrer"
        className="social-link"
      >
        <FontAwesomeIcon icon={faYoutube} />
      </a>
      <a
        href="https://wa.me/1234567890"
        target="_blank"
        rel="noreferrer"
        className="social-link"
      >
        <FontAwesomeIcon icon={faWhatsapp} />
      </a>
      <a
        href="https://t.me/your_telegram_handle"
        target="_blank"
        rel="noreferrer"
        className="social-link"
      >
        <FontAwesomeIcon icon={faTelegram} />
      </a>
    </div>
  );
};

export default SocialNavbar;
