import React from "react";
// import { Link } from "react-router-dom";
import "./Brand.css";

function Brand() {
  return (
    <div className="brand-content-container">
      <a href="/">
        <img
          className="brand-responsive-image"
          src="/Images/logo150x150.png"
          alt="logo"
        />
      </a>
      <div>
        <h5 style={{ margin: "0" }}>
          Maheshwaram College of Education and Law Management
        </h5>
        <h5 style={{ margin: "0" }}>
          माहेश्वरम कॉलेज ऑफ एजुकेशन एंड लॉ मैनेजमेंट
        </h5>

        <p style={{ textAlign: "center", margin: "0" }}>
          (Affiliated to Chaudhary Charan Singh University, Meerut & Approved by
          BCI, New Delhi)
        </p>
      </div>
    </div>
  );
}

export default Brand;
