import React from "react";
import CollegeLocation from "../../Components/UI/CollegeLocation";
function Contactus() {
  return (
    <div style={{ width: "100%" }}>
      <div className="page-head">
        <span>Contact Us</span>
      </div>
      <h6 style={{ textAlign: "left", font: "600" }}>Address:</h6>
      <p style={{ textAlign: "left", paddingLeft: "5rem" }}>
        <p className="no-margin">
          Maheshwaram College of Education And Law Management,
        </p>
        <p className="no-margin">
          Bulandshahr Bhensora, Near Siyana, Bulandshahr,
        </p>
        <p className="no-margin">Uttar Pradesh Pin : 245412</p>
      </p>

      <h6 style={{ textAlign: "left", font: "600" }}>Phone:</h6>
      <p
        className="no-margin"
        style={{ textAlign: "left", paddingLeft: "5rem" }}
      >
        9997908588 , 8938855524
      </p>
      <p
        className="no-margin"
        style={{ textAlign: "left", paddingLeft: "5rem" }}
      >
        9760082425 , 9627250700
      </p>
      <h6 style={{ textAlign: "left", font: "600" }}>e-Mail: </h6>
      <p style={{ textAlign: "left", paddingLeft: "5rem" }}>
        principalmclb[at]gmail[dot]com
      </p>
      <h6 style={{ textAlign: "left", font: "600" }}>College location: </h6>
      <div className="bordered-div">
        <CollegeLocation />
      </div>
    </div>
  );
}

export default Contactus;
