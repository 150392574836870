import React, { useState } from "react";
// import "./pages.css";

const images = [
  { src: "/Images/SliderImage/1.jpg", alt: "Image 1" },
  { src: "/Images/SliderImage/2.jpg", alt: "Image 2" },
  { src: "/Images/SliderImage/3.jpg", alt: "Image 3" },
  { src: "/Images/SliderImage/4.jpg", alt: "Image 4" },
  { src: "/Images/SliderImage/5.jpg", alt: "Image 5" },
  { src: "/Images/SliderImage/6.jpg", alt: "Image 6" },
  { src: "/Images/SliderImage/7.jpg", alt: "Image 7" },
  { src: "/Images/SliderImage/8.jpg", alt: "Image 8" },
  { src: "/Images/SliderImage/9.jpg", alt: "Image 9" },
];

function ImageGallery() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openGallery = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  const closeGallery = () => {
    setIsOpen(false);
  };

  const goToPrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <div className="image-grid-container">
      <div className="image-grid">
        {images.map((image, index) => (
          <img
            key={index}
            src={image.src}
            alt={image.alt}
            onClick={() => openGallery(index)}
            className="thumbnail"
          />
        ))}
      </div>

      {isOpen && (
        <div className="lightbox-overlay" onClick={closeGallery}>
          <div
            className="lightbox-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="lightbox-nav prev" onClick={goToPrevious}>
              &#10094; {/* Left arrow symbol */}
            </button>
            <img
              src={images[currentIndex].src}
              alt={images[currentIndex].alt}
              className="lightbox-image"
            />
            <button className="lightbox-nav next" onClick={goToNext}>
              &#10095; {/* Right arrow symbol */}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageGallery;
