import React from "react";

function Visionandmission() {
  return (
    <div>
      <div className="page-head">
        <span>Vission</span>
      </div>
      <p className="tabbed-paragraph">
        In today's world, legal education carries the profound responsibility of
        shaping a new generation of innovative and dynamic lawyers. Our mission
        is to equip them with not only professional skills but also a deep,
        comprehensive understanding of all aspects of law. Our aim is to ensure
        they are fully prepared to advocate for their clients with dedication
        and expertise, ensuring their clients' rights, needs, and overall
        satisfaction are met with the utmost integrity and commitment.
      </p>
      <p className="tabbed-paragraph">
        Legal knowledge is fundamental in differentiating between fairness and
        injustice, nobility and ignobility, and equity and inequality. We are
        dedicated to instilling values of service, sacrifice, sensitivity, and
        resilience, empowering our students to champion justice and advocate for
        the underprivileged in our society.
      </p>
      <p className="tabbed-paragraph">
        The legal profession stands among the few with the genuine potential to
        effect meaningful societal change. While we view ourselves as catalysts
        for this transformation, it is your passion and dedication that truly
        drives progress.
      </p>
      <p className="tabbed-paragraph">
        We are committed to delivering an integrated, high-quality education
        that not only enhances students' knowledge for leadership roles but also
        provides a robust foundation. Our curriculum blends theoretical
        management concepts with practical, real-world applications to prepare
        students for the dynamic global marketplace.
      </p>
      <p className="tabbed-paragraph">
        We firmly believe that a strong ethical framework, core values, and a
        sense of social justice will empower you to serve society effectively
        and pave the way for a successful legal career.
      </p>

      <h6>Mission</h6>
      <p className="tabbed-paragraph">
        Our mission is to be a leading center for legal education, distinguished
        by our commitment to excellence. We embrace modern teaching and training
        methodologies to prepare students for success in a competitive legal
        environment. Our focus is on nurturing legal ethics and social
        awareness, ensuring holistic personal development and preparing our
        students to excel in their careers with integrity and competence.
      </p>
    </div>
  );
}

export default Visionandmission;
