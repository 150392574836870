import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import Brand from "./Components/UI/Brand";
import Search from "./Components/UI/Search";
import SocialNavbar from "./Components/UI/SocialNavbar";
import Sidebar from "./Components/UI/Sidebar";

const LayoutFull = () => {
  const [toggleSidebar, setToggleSidebar] = useState(false);

  return (
    <>
      <div
        className={` ${toggleSidebar ? "dull" : ""}`}
        // onClick={() => setToggleSidebar(false)}
      >
        {/* ------------------------------ */}
        <div className="social-navbar-bg-color">
          <div className="socialNavbarContainer">
            <SocialNavbar />
            <div style={{ margin: "0.5rem 0" }}>
              <Search />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="header">
            <Brand />
          </div>
        </div>
        <div className="navbarContainer">
          <Sidebar
            toggleSidebar={toggleSidebar}
            setToggleSidebar={setToggleSidebar}
          />
          <nav className="navbar">
            <button
              className="sidebar-icon"
              onClick={() => setToggleSidebar(!toggleSidebar)}
            >
              {toggleSidebar ? (
                <svg fill="none" viewBox="0 0 15 15" height="1em" width="1em">
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M11.782 4.032a.575.575 0 10-.813-.814L7.5 6.687 4.032 3.218a.575.575 0 00-.814.814L6.687 7.5l-3.469 3.468a.575.575 0 00.814.814L7.5 8.313l3.469 3.469a.575.575 0 00.813-.814L8.313 7.5l3.469-3.468z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  viewBox="0 0 448 512"
                  fill="currentColor"
                  height="1em"
                  width="1em"
                >
                  <path d="M0 96c0-17.7 14.3-32 32-32h384c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32h384c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zm448 160c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h384c17.7 0 32 14.3 32 32z" />
                </svg>
              )}
            </button>
            <Navbar />
          </nav>

        </div>

        <div className="layout-full">
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default LayoutFull;
