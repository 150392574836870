import React, { useEffect, useRef } from "react";
const pdfFiles = [
  {
    name: "B.A.LL.B. exam date 3,5,7,10 july postpond",
    url: "/Circulars/B.A.LL.B. exam date 3,5,7,10 july postpond.pdf",
  },
  {
    name: "B.A.LL.B. June 2024 exam datesheet",
    url: "/Circulars/B.A.LL.B. June 2024 exam datesheet.pdf",
  },
  {
    name: "BA.LL.B june 2023 exam date sheet",
    url: "/Circulars/BA.LL.B june 2023 exam date sheet.pdf",
  },
  {
    name: "BA LLB exam datesheet Dec 2023",
    url: "/Circulars/BALLB exam DATESHEET DEC 2023.pdf",
  },
  {
    name: "LL.B. 3year course exam datesheet Dec-2023",
    url: "/Circulars/LL.B. 3year course exam datesheet Dec-2023.pdf",
  },
  {
    name: "LL.B. 4th sem exam code 4002 date 14 June 2023 postpond",
    url: "/Circulars/LL.B. 4th sem exam code 4002 date 14 June 2023 postpond.pdf",
  },
  {
    name: "LL.B. Code 6001 new exam date",
    url: "/Circulars/LL.B. CODE 6001 NEW EXAM DATE.pdf",
  },
  {
    name: "LL.B. June 2024 exam datesheet",
    url: "/Circulars/LL.B. June 2024 exam datesheet.pdf",
  },
  {
    name: "LLB 6th Sem exam postponed",
    url: "/Circulars/LLB 6TH SEM EXAM POSTPONED.pdf",
  },
  {
    name: "Related to LLB exam to be held on 26.06.2023",
    url: "/Circulars/Related to LLB Exam to be Held on 26.06.2023.pdf",
  },
  {
    name: "Revised B.A.LL.B. datesheet June 2023",
    url: "/Circulars/REVISED B.A.LL.B. DATE SHEET JUNE 2023.pdf",
  },
  // Add more files as needed{ name: "File 6", url: "/path/to/file6.pdf" },
];

function AllCircularsFromFile() {
  return (
    <div>
      {/* <h6 className="divider-full-width">Uploaded Circulars</h6> */}
      <div className="page-head">
        <span>Notices & Circulars</span>
      </div>
      <table className="pdf-table">
        <thead>
          <tr>
            <th>Title</th>
            {/* <th>Posted On</th> */}
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          {pdfFiles.map((pdf) => (
            <tr key={pdf.id}>
              <td>
                <img
                  src="/images/pdf.png"
                  alt=""
                  style={{ width: "24px", height: "24px", marginRight: "8px" }}
                />
                {pdf.name}
              </td>
              {/* <td style={{ textAlign: "center" }}>{pdf.uploaded_at}</td> */}
              <td style={{ textAlign: "center" }}>
                <a href={pdf.url} download={pdf.name}>
                  Download
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="divider-full-width"></div>
    </div>
  );
}

export default AllCircularsFromFile;
