import { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import Layout from "./Layout";
import LayoutFull from "./LayoutFull"; // New layout without sidebars
import LayoutAdmin from "./LayoutAdmin"; // Admin-specific layout
// import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Chairmansmessage from "./pages/AboutUs/Chairmansmessage";
import Visionandmission from "./pages/AboutUs/Visionandmission";
import Aimandobjective from "./pages/AboutUs/AimandObjective";
import Dresscode from "./pages/AboutUs/Dresscode";
import Discipline from "./pages/AboutUs/Discipline";
import Generalrules from "./pages/AboutUs/Generalrules";

import AdmissionProcedure from "./pages/Admission/AdmissionProcedure";
import CourseAndProgramme from "./pages/Courses/CourseAndProgramme";
import Recognition from "./pages/Recognition";
import Contactus from "./pages/AboutUs/Contactus";
import Curriculum from "./pages/Curriculum";
import ImageGallery from "./pages/ImageGallery";

// import RegistrationForm from "./pages/RegistrationForm";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import StudentLogin from "./pages/Admin/StudentLogin";
import Landingpage from "./pages/Landingpage";
import Login from "./pages/Admin/Adminlogin";
import UploadPhoto from "./pages/Admin/UploadPhoto";
import Registeruser from "./pages/Admin/Registeruser";
import UploadCircular from "./pages/Admin/UploadCircular";
import Admincirculars from "./pages/Admin/Admincirculars";
import AdminGallery from "./pages/Admin/AdminGallery";

import AllCirculars from "./pages/AllCirculars";
import CircularsFromfile from "./Components/CircularsFromfile";
import AllCircularsFromFile from "./pages/AllCircularsFromFile";

const App = () => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* Main Layout with Sidebars */}
        <Route path="/" element={<Layout />}>
          <Route path="about-us" element={<AboutUs />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route
            path="about-us/chairmans-message"
            element={<Chairmansmessage />}
          />
          <Route
            path="about-us/vision-and-mission"
            element={<Visionandmission />}
          />
          <Route
            path="about-us/aim-and-objective"
            element={<Aimandobjective />}
          />
          <Route path="about-us/dress-code" element={<Dresscode />} />
          <Route path="about-us/discipline" element={<Discipline />} />
          <Route path="about-us/general-rules" element={<Generalrules />} />
          <Route path="recognition" element={<Recognition />} />
          <Route
            path="courses/courses-and-programmes"
            element={<CourseAndProgramme />}
          />
          <Route
            path="admission/admission-procedure"
            element={<AdmissionProcedure />}
          />
          <Route path="admission/allcirculars" element={<AllCirculars />} />
          <Route path="contactus" element={<Contactus />} />
          <Route path="curriculum" element={<Curriculum />} />
          <Route path="imagegallery" element={<ImageGallery />} />
          <Route path="studentlogin" element={<StudentLogin />} />
          <Route path="adminlogin" element={<Login setToken={setToken} />} />
          <Route path="registeruser" element={<Registeruser />} />
          <Route path="circularsfromfile" element={<CircularsFromfile/>}/>
          <Route path="allcircularsfromfile" element={<AllCircularsFromFile/>}/>
        </Route>

        {/* Full-Width Layout without Sidebars */}
        <Route path="/" element={<LayoutFull />}>
          <Route index element={<Landingpage />} />
        </Route>

        {/* Admin Layout */}
        <Route path="/admin" element={<LayoutAdmin />}>
          {/* <Route path="login" element={<AdminLogin />} /> */}
          {/* <Route path="dashboard" element={<AdminDashboard />} /> */}
          <Route
            path="uploadcircular"
            element={
              token ? (
                <UploadCircular token={token} />
              ) : (
                <Login setToken={setToken} />
              )
            }
          />
          <Route
            path="admincirculars"
            element={
              token ? (
                <Admincirculars token={token} />
              ) : (
                <Login setToken={setToken} />
              )
            }
          />
          <Route
            path="uploadphoto"
            element={
              token ? (
                <UploadPhoto token={token} />
              ) : (
                <Login setToken={setToken} />
              )
            }
          />

          <Route
            path="admingallery"
            element={
              token ? (
                <AdminGallery token={token} />
              ) : (
                <Login setToken={setToken} />
              )
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
