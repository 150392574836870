import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AdminGallery from "./AdminGallery";

const Upload = ({ token, onUploadSuccess }) => {
  const [file, setFile] = useState(null);
  const formUpload = useRef(null);
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");

  const [refreshKey, setRefreshKey] = useState(0);
  const handleRefresh = () => {
    // Update the refreshKey to force re-rendering of the UploadedGallery component
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const showMessageWithType = (type, msg) => {
    setMessageType(type);
    setMessage(msg);
    setShowMessage(true);
  };

  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 5000); // Display message for 3 seconds
      return () => clearTimeout(timer); // Clear timeout if component unmounts
    }
  }, [showMessage]);

  //----------------
  useEffect(() => {
    // Check if the token is present and valid
    const checkToken = () => {
      if (!token) {
        showMessageWithType(
          "error",
          "You need to be logged in to access this page."
        );
        console.log("upload photo token error", token);
        navigate("/Adminlogin"); // Redirect to login if no token
        return;
      }

      // Optionally check token validity (e.g., decode and check expiration)
      try {
        const { exp } = JSON.parse(atob(token.split(".")[1])); // Decode JWT payload
        if (exp * 1000 < Date.now()) {
          // Token is expired
          console.log("upload photo token expired", exp * 1000);
          showMessageWithType(
            "error",
            "Your session has expired. Please log in again."
          );
          navigate("/adminlogin");
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        showMessageWithType(
          "error",
          "An error occurred while validating your session. Please log in again."
        );
        navigate("/adminlogin");
      }
    };

    checkToken();
  }, [token, navigate]);

  //----------------

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    // setFile(e.target.files[0]);

    if (selectedFile) {
      const maxFileSize =
        parseInt(process.env.REACT_APP_MAX_IMAGE_SIZE, 10) || 1 * 1024 * 1024; // Default to 1 MB if not set
      // Fetch the allowed image types from the .env file and split into an array
      const allowedTypes = process.env.REACT_APP_ALLOWED_IMAGE_TYPES
        ? process.env.REACT_APP_ALLOWED_IMAGE_TYPES.split(",").map((type) =>
            type.trim()
          )
        : [];
      if (!allowedTypes.includes(selectedFile.type)) {
        showMessageWithType(
          "error",
          "Invalid file type. Please select an image file (JPEG, JPG, PNG, or WEBP)."
        );
        setFile(null);
        formUpload.current.reset();
        return;
      }
      // Validate file size
      if (selectedFile.size > maxFileSize) {
        // const fileSizeInMB = (selectedFile.size / (1024 * 1024)).toFixed(2);
        showMessageWithType(
          "error",
          `File size exceeds the limit of ${process.env.REACT_APP_MAX_IMAGE_SIZE_ERROR}. Please select a smaller file.`
        );
        setFile(null);
        formUpload.current.reset();
        return;
      }
      setFile(selectedFile);
      setMessage("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      showMessageWithType("error", "No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("image", file);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/upload`,
        // `http://localhost:5000/api/upload`,  // for testing only
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, //ensure backticks ` are used with bearer $token
            "Content-Type": "multipart/form-data",
          },
        }
      );

      formUpload.current.reset(); // Reset the form after submission
      setFile(null); // Clear the file state
      showMessageWithType("success  KK 1", response.data.message);

      // Call the onUploadSuccess prop to refresh the gallery
      if (response.status === 200) {
        if (onUploadSuccess) {
          onUploadSuccess(response.data); // this will show the response received from server
        }
        handleRefresh();
        showMessageWithType("success", "Image Uploaded Successfully.");
      }
      // if (onUploadSuccess) onUploadSuccess();
    } catch (err) {
      showMessageWithType("error", "Error uploading Image");
      console.error(
        "Error details:",
        err.response ? err.response.data : err.message
      );
      navigate("/adminlogin");
    }
  };

  return (
    <div className="container">
      <div className="upload-container">
        <div className="page-head">
          <div className="page-head-container">
            <span>Upload Photo to Gallery</span>
            <div className="rightside-image">
              <img
                style={{ marginTop: "10px", height: "2.5rem", width: "auto" }}
                src="/Images/upload.png"
                alt=""
              ></img>
            </div>
          </div>
        </div>

        <form
          style={{ width: "100%" }}
          onSubmit={handleSubmit}
          ref={formUpload}
        >
          <div style={{ justifyContent: "center", textAlign: "center" }}>
            {showMessage && (
              <p className={`${messageType} ${!showMessage && "hidden"}`}>
                {message}
              </p>
            )}
            <div className="uploadfile-control">
              <input
                type="file"
                onChange={handleFileChange}
                accept={process.env.REACT_APP_ALLOWED_FILE_TYPES}
              />
              <button type="submit">Upload</button>
              {/* <button className="refresh-button" onClick={handleRefresh}>
                Reload Gallery
              </button> */}
            </div>
          </div>
        </form>
      </div>
      <div style={{ width: "auto" }}>
        <AdminGallery key={refreshKey} token={token} />{" "}
      </div>
    </div>
  );
};
export default Upload;
