import React from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  // Function to handle logout
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/adminlogin");
    window.location.reload();
  };
  return (
    <div>
      <button className="component-logout-button"
        onClick={handleLogout}
      >
        Logout
      </button>
    </div>
  );
};

export default Logout;
