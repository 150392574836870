import React from "react";
import { Link } from "react-router-dom";

// Tags (menu items) with submenus
const Tags = [
  { name: "Home", link: "/" },
  { name: "About Us", link: "/about-us" },
  { name: "Courses & Programmes", link: "/courses/courses-and-programmes" },
  { name: "Admission Procedure", link: "/admission/admission-procedure" },
  { name: "Gallery", link: "/imagegallery" },
  { name: "Contact Us", link: "/contactus" },
];

function QuickLinks() {
  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      <ul className="quicklink-ul">
        {Tags.map((item, index) => (
          <li key={index} className="quicklink-li">
            <Link
              to={item.link}
              className="quicklink-li-link"
              onClick={handleClick} // Scroll to top when clicked
            >
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default QuickLinks;
