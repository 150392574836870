import React, { useEffect, useRef } from "react";
const pdfFiles = [
  {
    name: "B.A.LL.B. exam date 3,5,7,10 july postpond",
    url: "/Circulars/B.A.LL.B. exam date 3,5,7,10 july postpond.pdf",
  },
  {
    name: "B.A.LL.B. June 2024 exam datesheet",
    url: "/Circulars/B.A.LL.B. June 2024 exam datesheet.pdf",
  },
  {
    name: "BA.LL.B june 2023 exam date sheet",
    url: "/Circulars/BA.LL.B june 2023 exam date sheet.pdf",
  },
  {
    name: "BA LLB exam datesheet Dec 2023",
    url: "/Circulars/BALLB exam DATESHEET DEC 2023.pdf",
  },
  {
    name: "LL.B. 3year course exam datesheet Dec-2023",
    url: "/Circulars/LL.B. 3year course exam datesheet Dec-2023.pdf",
  },
  {
    name: "LL.B. 4th sem exam code 4002 date 14 June 2023 postpond",
    url: "/Circulars/LL.B. 4th sem exam code 4002 date 14 June 2023 postpond.pdf",
  },
  {
    name: "LL.B. Code 6001 new exam date",
    url: "/Circulars/LL.B. CODE 6001 NEW EXAM DATE.pdf",
  },
  {
    name: "LL.B. June 2024 exam datesheet",
    url: "/Circulars/LL.B. June 2024 exam datesheet.pdf",
  },
  {
    name: "LLB 6th Sem exam postponed",
    url: "/Circulars/LLB 6TH SEM EXAM POSTPONED.pdf",
  },
  {
    name: "Related to LLB exam to be held on 26.06.2023",
    url: "/Circulars/Related to LLB Exam to be Held on 26.06.2023.pdf",
  },
  {
    name: "Revised B.A.LL.B. datesheet June 2023",
    url: "/Circulars/REVISED B.A.LL.B. DATE SHEET JUNE 2023.pdf",
  },
  // Add more files as needed{ name: "File 6", url: "/path/to/file6.pdf" },
];

function CircularsFromfile() {
  const listRef = useRef(null);
  const scrollIntervalRef = useRef(null);

  useEffect(() => {
    const listElement = listRef.current;

    const startAutoScroll = () => {
      scrollIntervalRef.current = setInterval(() => {
        if (listElement) {
          // Scroll down by 1 pixel
          listElement.scrollTop += 1;

          // If the end is reached, reset to top
          if (
            listElement.scrollTop + listElement.clientHeight >=
            listElement.scrollHeight
          ) {
            listElement.scrollTop = 0;
          }
        }
      }, 50); // Adjust speed here (50ms delay between scrolls)
    };

    startAutoScroll();

    // Clear the interval on component unmount
    return () => clearInterval(scrollIntervalRef.current);
  }, []);

  const handleMouseEnter = () => {
    // Pause the scroll when hovering over the list
    clearInterval(scrollIntervalRef.current);
  };

  const handleMouseLeave = () => {
    // Resume the scroll when not hovering
    const listElement = listRef.current;
    scrollIntervalRef.current = setInterval(
      () => {
        if (listElement) {
          listElement.scrollTop += 1;

          if (
            listElement.scrollTop + listElement.clientHeight >=
            listElement.scrollHeight
          ) {
            listElement.scrollTop = 0;
          }
        }
      },
      50 //scroll speed  moves 1 pxl every 50ms
    );
  };
  return (
    <div
      className="pdfListContainer "
      ref={listRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ul className="pdfList">
        {pdfFiles.map((file, index) => (
          <li key={index} className="pdfListItem">
            {/* without calender */}
            <div className="notice-row">
              <a href={file.url} target="_blank" rel="noopener noreferrer">
                {file.name}
              </a>
            </div>
            {/* with calender :before */}
            {/* <div className="event-row">
              <div className="event-calend">
                <div className="event-month">Jul 23</div>
                <div className="event-date">25</div>
              </div>
              <div className="notice-row">
                <a href={file.url} target="_blank" rel="noopener noreferrer">
                  {file.name}
                </a>
              </div>
            </div> */}
            {/* with calender */}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CircularsFromfile;
