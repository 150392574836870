import React from "react";

function AdmissionProcedure() {
  return (
    <div>
      <div className="page-head">
        <span>Admission Procedure</span>
      </div>
      <p className="tabbed-paragraph ">
        Maheshwaram Law College offers both 3-Year LLB and 5-Year BA.LLM
        programs. The college follows a straightforward and hassle-free
        admission process. The procedure begins with announcements in national
        and regional newspapers. Additionally, Maheshwaram Law College employs
        various communication channels, such as digital campaigns on its
        website, educational portals, and other electronic mediums, to inform
        prospective students about the admissions process.
      </p>
      <p className="tabbed-paragraph">
        The admission form, along with a brochure, is available both online and
        offline. A dedicated admissions cell, supervised by the Head of
        Admissions, ensures that the process is fast and easy for students. This
        team also provides guidance and counseling on program details and
        addresses any questions students may have.
      </p>
      <p className="no-margin lead">
        Procedure as Emphasized by C.C.S. University, Meerut:
      </p>
      <ul>
        <li>
          <span style={{ fontWeight: "600" }}>
            Merit after Online Registration:
          </span>
          All students must accurately provide their information and attach a
          recent photograph. Each document attached to the admission form must
          be self-attested.
        </li>
        <li>
          <span style={{ fontWeight: "600" }}>
            Students from Other Universities:
          </span>
          Candidates coming from universities other than CCS University, Meerut,
          must submit a transfer certificate, character certificate, and
          migration certificate within 15 days of admission.
        </li>
        <li>
          <span style={{ fontWeight: "600" }}>Submission of Documents:</span>
          All students must submit their transfer certificate, a declaration,
          and the required fee within five days of the declaration of the
          admission merit list.
        </li>
      </ul>
      <p className="tabbed-paragraph">
        The college reserves the right to cancel admission at any time if any
        information provided by the student is found to be incorrect or
        misleading.
      </p>
      <p className="tabbed-paragraph">
        Candidates are shortlisted based on the eligibility criteria of the
        course they applied for and are invited for a Personal Interview (PI).
      </p>
      <p className="tabbed-paragraph">
        The application form should be filled out completely and accurately. The
        completed application form can be submitted online, or a hard copy of
        the filled form can be sent to the following address:
      </p>
      <p style={{ textAlign: "left", paddingLeft: "5rem" }}>
        <p className="no-margin">
          <span style={{ fontWeight: "600" }}>To</span>
          <p></p>
          Maheshwaram College of Education And Law Management,
        </p>
        <p className="no-margin">
          Bulandshahr Bhensora, Near Siyana, Bulandshahr,
        </p>
        <p className="no-margin">Uttar Pradesh Pin : 245412</p>
      </p>
    </div>
  );
}

export default AdmissionProcedure;
