import React from "react";

function AboutUs() {
  return (
    <div>
      <div className="page-head">
        <span>About Us</span>
      </div>

      <p className="drop-case">
        Maheshwaram College of Education and Law Management, located in
        Bulandshahr, is renowned as one of the premier law colleges in Uttar
        Pradesh. The institution is dedicated to providing high-quality
        education and rigorous training programs designed to equip students with
        the skills and knowledge necessary to become fully qualified lawyers.
        Our comprehensive curriculum covers various aspects of legal education,
        ensuring that graduates are well-prepared to excel in their legal
        careers.
      </p>
      <p className="tabbed-paragraph">
        The college emphasizes not only academic excellence but also practical
        training, offering students opportunities to engage in internships, moot
        court sessions, and legal clinics. This hands-on experience is crucial
        in developing the practical skills required in the legal profession.
      </p>
      <p className="tabbed-paragraph">
        It's important to note that the structure and content of law education,
        as well as the qualifications required to practice law, can vary
        significantly around the world. At Maheshwaram College, we strive to
        provide an education that meets international standards, preparing our
        students to navigate the diverse and complex legal landscapes they may
        encounter in their careers.
      </p>
      <p className="tabbed-paragraph">
        Our commitment to excellence in legal education has positioned us as a
        leading institution in the region, attracting students from various
        backgrounds who are passionate about pursuing a career in law."
      </p>
    </div>
  );
}

export default AboutUs;
