import React, { useState } from "react";
import { Link } from "react-router-dom";
// Menu data with optional submenus

const Navbar = () => {
  const menuItems = [
    { name: "Home", link: "/" },
    {
      name: "About Us",
      link: "/about-us",
      submenu: [
        { name: "Chairman's Message", link: "/about-us/chairmans-message" },
        { name: "Vision and Mission", link: "/about-us/vision-and-mission" },
        { name: "Aim and Objective", link: "/about-us/aim-and-objective" },
        { name: "Dress Code", link: "/about-us/dress-code" },
        { name: "Discipline", link: "/about-us/discipline" },
        { name: "General Rules", link: "/about-us/general-rules" },
      ],
    },
    // { name: "Recognition", link: "/recognition" },
    {
      name: "Courses",
      // link: "#",
      submenu: [
        {
          name: "Courses & Programmes",
          link: "/courses/courses-and-programmes",
        },
      ],
    },

    {
      name: "Academics",
      // link: "#",
      submenu: [
        { name: "Admission Procedure", link: "/admission/admission-procedure" },
        { name: "Notices & Circulars", link: "/admission/allcirculars" },
      ],
    },

    // { name: "Curriculum", link: "/curriculum" },
    { name: "Gallery", link: "/imagegallery" },

    { name: "Contact Us", link: "/contactus" },
    {
      name: "Login",
      // link: "",
      submenu: [
        { name: "Admin login", link: "/adminlogin" },
        { name: "Student login", link: "/studentlogin" },
      ],
    },
  ];

  // State to track which menu item is active for submenu visibility
  const [activeMenu, setActiveMenu] = useState(null);

  // Handle mouse enter to show submenu
  const selectedNavbarMenuItem = (name) => {
    setActiveMenu(name);
  };

  // Handle mouse leave to hide submenu
  const handleMouseLeave = () => {
    setActiveMenu(null);
  };

  return (
    <ul className="navbar-items" id="navbar-menu">
      {menuItems.map((item, index) => (
        <li
          key={index}
          // className={`${activeMenu === index ? "activeNavbarItem" : ""}`}
          className="navbar-items-li"
          onMouseEnter={() => item.submenu && selectedNavbarMenuItem(index)}
          onMouseLeave={() => item.submenu && handleMouseLeave()}
        >
          <Link to={item.link} className="navbar-items-link">
            {item.name}
          </Link>

          {/* Submenu items */}
          {item.submenu && activeMenu === index && (
            <ul className="submenuContainer">
              {item.submenu.map((subItem, subIndex) => (
                <li key={subIndex} className="submenu-li">
                  <Link to={subItem.link} className="submenu-link">
                    {subItem.name}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};

export default Navbar;
