import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import "./Excerpt.css";
function Excerpt({ text, maxLength = 100, targetPage = '/' }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  // Determine if the text needs to be truncated
  const isTruncated = text.length > maxLength;

  // Get the excerpt if text needs truncation
  const excerpt = isTruncated && !isExpanded ? text.slice(0, maxLength) + '...' : text;

  const handleReadMore = () => {
    if (!isExpanded) {
      navigate(targetPage);
    } else {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <div className="excerptContainer">
      <p className="excerptText">{excerpt}</p>
      {isTruncated && (
        <button className="readMoreButton"  onClick={handleReadMore}>
          {isExpanded ? 'Show Less' : 'Read More...'}
        </button>
      )}
    </div>
  );
}

export default Excerpt;
