import React from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "../Components/UI/Carousal";
import LatestCirculars from "../Components/LatestCirculars";
// import RegistrationForm from "./RegistrationForm";
import Excerpt from "../Components/Excerpt";
// import Registeruser from "./Admin/Registeruser";
import { Link } from "react-router-dom";
import AllCirculars from "./AllCirculars";
import CircularsFromfile from "../Components/CircularsFromfile";

function Landingpage() {
  const excerptText =
    "Maheshwaram College of Education and Law Management, located in Bulandshahr, is renowned as one of the premier law colleges in Uttar Pradesh. The institution is dedicated to providing high-quality education and rigorous training programs designed to equip students with the skills and knowledge necessary to become fully qualified lawyers. Our comprehensive curriculum covers various aspects of legal education";
  const excerptAdmissionProcedure =
    "Maheshwaram Law College offers both 3-Year LLB and 5-Year BA.LLM programs. The college follows a straightforward and hassle-free admission process. The procedure begins with announcements in national and regional newspapers. Additionally, Maheshwaram Law College employs various communication channels, such as digital campaigns on its website, educational portals, and other electronic mediums, to inform prospective students about the admissions process.";
  const navigate = useNavigate();
  const handleReadMore = () => {
    // navigate("/admission/allcirculars"); // commented as serving from the filesystem in place of db
    navigate("/allcircularsfromfile");
  };
  return (
    <div>
      <Carousel />

      <div className="scroll-container">
        <div className="scroll-text">
          Maheshwaram College of Education and Law Mangement
        </div>
      </div>
      <div className="section-one">
        <div className="container">
          <div className="box-row">
            <div className="box-wrap">
              <div className="box-head">
                <span>Latest Circulars</span>
              </div>
              <div style={{ paddingRight: "1rem" }}>
                {/* <LatestCirculars />  Commented because it is seved from database */}
                <CircularsFromfile />
                <div style={{ textAlign: "right" }}>
                  <button className="readMoreButton" onClick={handleReadMore}>
                    view all
                  </button>
                </div>
              </div>
            </div>
            <div className="box-wrap">
              <div className="box-head">
                <span>Chairman's message</span>
              </div>
              <div
                style={{
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src="/Images/ppdummy.jpeg" alt="chairman" />
              </div>
              <div style={{ padding: "0 1rem 1rem 1rem" }}>
                <Excerpt
                  text={excerptText}
                  maxLength={100}
                  targetPage="/about-us"
                />
              </div>
            </div>
            <div className="box-wrap">
              <div className="box-head">
                <span>Admission Procedure</span>
              </div>
              <div
                style={{
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Link to="/registeruser">
                  <img
                    className="brand-register-image"
                    src="/Images/reg.gif"
                    alt="Registration"
                  />
                </Link>
              </div>
              <div
                className="box-body"
                style={{ paddingRight: "1rem", paddingTop: "2rem" }}
              >
                <div style={{ padding: "0 1rem 1rem 1rem" }}>
                  <Excerpt
                    text={excerptAdmissionProcedure}
                    maxLength={295}
                    targetPage="/admission/admission-procedure"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-two">
        <div className="title-focus"></div>
        <div className="container">
          <div className="box-row">
            <div
              className="box-wrap"
              style={{ textAlign: "center", height: "200px" }}
            >
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <figure style={{ height: "200px" }}>
                      <img
                        src="/Images/downloadsfile.jpg"
                        alt="Admission open"
                        style={{ height: "200px", width: "auto" }}
                      ></img>
                    </figure>
                  </div>
                  <div className="flip-card-back">
                    <div className="squarebox-text">Downloads</div>
                    <p className="px-5"></p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="box-wrap"
              style={{ textAlign: "center", height: "200px" }}
            >
              <a href="/admission/admission-procedure">
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <figure style={{ height: "200px" }}>
                        <img
                          src="/Images/AdmissionOpen.png"
                          alt="Admission open"
                          style={{ height: "200px", width: "auto" }}
                        ></img>
                      </figure>
                    </div>
                    <div className="flip-card-back">
                      <div className="squarebox-text">
                        Admission Open 2024-25
                      </div>
                      <p className="px-5"></p>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div
              className="box-wrap"
              style={{ textAlign: "center", height: "200px" }}
            >
              <a href="/registeruser">
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <figure style={{ height: "200px" }}>
                        <img
                          src="/Images/onlineregistration.jpg"
                          alt="Admission open"
                          style={{ height: "200px", width: "auto" }}
                        ></img>
                      </figure>
                    </div>
                    <div className="flip-card-back">
                      <div className="squarebox-text">Online Registration</div>
                      <p className="px-5"></p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="section-two">
        <div className="title-focus">What We Offer</div>
        <div className="container">
          <div class="square-container">
            <div class="square-box">
              <div className="squarebox-text">Carrear counselling session</div>
            </div>
            <div class="square-box">
              <div className="squarebox-text">
                Legal and Correctional Learning Tools
              </div>
            </div>
            <div class="square-box">
              <div className="squarebox-text">Featured Speaker Sessions</div>
            </div>
            <div class="square-box">
              <div className="squarebox-text">Mock Court Proceedings</div>
            </div>
            <div class="square-box">
              <div className="squarebox-text">Legal Training Internship</div>
            </div>
            <div class="square-box">
              <div className="squarebox-text">Assured Placement Success</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one">
        <div className="title-focus">Affiliation & Accreditation</div>
        <div className="container">
          <div className="box-row">
            <div className="box-wrap" style={{ textAlign: "center" }}>
              <img
                src="/Images/CCSU-Logo.png"
                alt="CCS University"
                style={{ height: "200px", width: "auto" }}
              ></img>
            </div>
            <div className="box-wrap" style={{ textAlign: "center" }}>
              <img
                src="/Images/BCI-logo.png"
                alt="BCI"
                style={{ height: "200px", width: "auto" }}
              ></img>
            </div>

            <div className="box-wrap" style={{ textAlign: "center" }}>
              <img
                src="/Images/UPgovt-logo.png"
                alt="UP Govt"
                style={{ height: "200px", width: "auto" }}
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landingpage;
