import React from "react";

function Generalrules() {
  return (
    <div>
      <div className="page-head">
        <span>Genral Rules and Regulations</span>
      </div>
      <p>
        <ol>
          <li>
            A candidate shall not be entitled to claim admission as a matter of
            right even if he/she is otherwise eligible. The college reserves the
            right to refuse admission to any individual without assigning any
            reason.
          </li>

          <li>
            The candidate is required to produce original certificates,
            documents, character certificate, statements of requisite percentage
            of marks of the qualifying examination at the time of
            interview/reporting for admission, interviewed/ allowed to complete
            admission formalities.
          </li>

          <li>
            No candidate shall be admitted after the last date of admission.
          </li>

          <li>
            Candidates awaiting results of improvement of performance
            examination shall not be admitted.
          </li>

          <li>
            The academic year shall be announced by the University in advanced
            each year and shall ordinarily be between July to June with winter
            break and summer vacations.
          </li>
        </ol>
      </p>
    </div>
  );
}

export default Generalrules;
