import React from "react";
import "../App.css";
import Excerpt from "./Excerpt";
// import CollegeLocation from "./UI/CollegeLocation";
import QuickLinks from "./UI/QuickLinks";
import SocialNavbar from "./UI/SocialNavbar";
function Footer() {
  const iconStyle = {
    fontSize: "1.5rem",
    marginRight: "0.5rem",
  };

  const excerptText =
    "Maheshwaram College of Education and Law Management, located in Bulandshahr, is renowned as one of the premier law colleges in Uttar Pradesh. The institution is dedicated to providing high-quality education and rigorous training programs designed to equip students with the skills and knowledge necessary to become fully qualified lawyers. Our comprehensive curriculum covers various aspects of legal education";

  return (
    <>
      <div className="footer">
        <div className="footer-upper">
          <div className="footer-column">
            <h6 className="divider-full-width">About Us</h6>
            <Excerpt
              text={excerptText}
              maxLength={200}
              targetPage="/about-us"
            />
          </div>
          <div className="footer-column">
            <h6 className="divider-full-width">Quick Links</h6>
            {/* <CollegeLocation /> */}
            <QuickLinks />
            <SocialNavbar />
          </div>
          <div className="footer-column">
            <h6 className="divider-full-width">Contact Us</h6>
            <p className="no-margin">
              <p>
                Maheshwaram College of Education And Law Management, Bulandshahr
                Bhensora, Near Siyana, Bulandshahr, Uttar Pradesh Pin : 245412
              </p>
              <p className="no-margin">
                {"\uD83D\uDCF1"} 9997908588 , 8938855524
              </p>
              <p className="no-margin">
                {"\uD83D\uDCF1"} 9760082425 , 9627250700
              </p>
              <p className="no-margin">
                <span style={iconStyle}>✉️</span>
                <a style={{color:"white"}} href="mailto:principalmclb.gmail.com" target="_blank" rel="noreferrer">principalmclb[at]gmail[dot]com</a>
              </p>
              <p className="iconStyle"></p>
            </p>
          </div>
        </div>
        <div className="footer-lower">
          <p className="no-margin" style={{ textAlign: "center" }}>
            Copyright © 2024 Maheshwaram College of Law & Management. All rights
            reserved.
          </p>
          <p className="no-margin" style={{ textAlign: "center" }}>
            <span>
              Designed &amp; Developed By:{" "}
              <a href="mailto:sharmakk@live.com" target="_blank" rel="noreferrer">
                Bighead Infotech Pvt. Ltd.
              </a>
            </span>{" "}
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
