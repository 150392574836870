import React from "react";

function Messagefmchairman() {
  return (
    <div>
      <div className="page-head">
        <span>Chairman's Message</span>
      </div>
      <img
        className="chairman-image"
        src="/Images/ppdummy.jpeg"
        alt="chairman"
      ></img>
      <p className="drop-case">
        As Chairman of Maheshwaram College of Education and Law Management, I am
        deeply passionate about redefining the landscape of legal education. At
        our esteemed institution, we hold a firm belief that legal education
        should transcend the boundaries of traditional professionalism. It
        should serve as a powerful reflection of social values and ethical
        principles, ensuring that our students are not only well-versed in legal
        theory but also prepared to navigate and influence the ever-changing
        global and corporate landscapes.
      </p>
      <p className="tabbed-paragraph">
        Our mission at Maheshwaram College of Education and Law Management is to
        provide a legal education that stands unparalleled in its dedication and
        quality. We are committed to nurturing young lawyers who are equipped
        with exceptional knowledge, honed skills, and a strong sense of dignity.
        Our approach goes beyond merely achieving academic qualifications; we
        strive to instill a deep-seated sense of loyalty and ethical
        responsibility, ensuring that our graduates are prepared to uphold the
        highest standards of integrity throughout their legal careers.
      </p>
      <p className="tabbed-paragraph">
        We are steadfast in our commitment to offering a comprehensive education
        that spans all branches of law. Our curriculum is meticulously designed
        to prepare students for success in a global legal system that is
        increasingly complex and interconnected. We aim to develop individuals
        who are not only adept at independent decision-making but also skilled
        at navigating the multifaceted challenges of the modern business world.
      </p>
      <p className="tabbed-paragraph">
        With immense pride, I affirm that Maheshwaram College of Education and
        Law Management is dedicated to equipping our students to meet the
        demands of contemporary life with both grace and resilience. Our success
        is built upon a disciplined, supportive, and collaborative environment
        that meets the high expectations of both our students and their
        families. We are continuously striving for excellence and integrity,
        pushing the boundaries of what is possible in legal education.
      </p>
      <p className="tabbed-paragraph">
        I extend a warm invitation to join us at Maheshwaram College of
        Education and Law Management. Here, you will experience a transformative
        learning environment that embodies the true spirit of education.
        Together, we will embark on a journey toward achieving greatness, driven
        by our unwavering commitment to excellence and ethical practice.
      </p>
    </div>
  );
}

export default Messagefmchairman;
